.item {
    margin: 1rem 0;
  }
  
  .image {
    max-width: 300px;
    height: 20rem;
    overflow: hidden;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
   
  }
  
img {
    max-width: 300px;
    width: 100%;
    object-fit: cover;
    height: 14rem;
    overflow: hidden;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    margin:auto;
    padding: 1rem;

  }
  
  .content {
    text-align: center;
    padding: 1rem;
  }
  
  .content h5 {
    /* font-size: 0.5rem; */
    color: #2c292b;
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
  }
  
  .actions {
    padding: 1.5rem;
    text-align: center;
  }
  
  .actions a {
    font: inherit;
    cursor: pointer;
    color: #77002e;
    border: 1px solid #77002e;
    background-color: transparent;
    padding: 0.5rem 1.5rem;
    border-radius: 4px;
  }
  
  .actions:hover,
  .actions:active {
    color: #677E84;
  }


