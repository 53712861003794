.main {
    margin: 1rem auto;
    width: 90%;
    /* max-width: 40rem; */
  
   
  }

.bg {
  background-image: url(../projects/assets/images/gardenbed1.jpeg);
  height: auto;
  
};

.container{
/* min-height: 100vh; */
}


