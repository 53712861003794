html, body, #app, #app>div {
  height: 100%
}

@media screen and (max-width: 768px) {
  Layout{
    min-width: 80vw;
    margin:auto;
  }

}
