h1 {
    color:#9C030C ;
    font-family: 'Tangerine', cursive;
    font-size: 11rem;
    font-weight: 900px;
    /* margin-left: 3rem; */
    text-shadow:   -3px -3px 0 white,
    3px -3px 0 white,
    -3px 3px 0 whitefamily
}

.logo {
  width:100%;
  margin: auto;
}

p{
  font-size: 1.5rem;
  font-family: 'Open Sans', sans-serif;
}

@supports (-webkit-text-stroke: 1px #9C030C) {
    h1 {
      -webkit-text-stroke: 0.1rem #9C030C;
      -webkit-text-fill-color: 100px #9C030C;
    }
  }


/* media queries */
@media screen and (max-width: 768px) {
  h1{
    margin:auto !important;
  }
}