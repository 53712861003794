nav {

    font-family: 'Tangerine', cursive;
    width: 100%;
    font-size: 2.5rem;;
    display: flex;
    /* align-items: center; */
    /* justify-content: space-between; */
    /* background-color: teal; */
    /* background-color: #28875E; */
    padding: 0 10%;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }

nav a, li{
  color:black;
  text-decoration: none;
  text-shadow:   -1px -1px 0 white,
  1px -1px 0 white,
  -1px 1px 0 white,
  1px 1px 0 white;
  list-style-type: none ;
  font-weight: 900;


  /* color: #F4F8F5; */
}
 nav a:hover{
  
  border-radius: 20%;
  background-color: #C8B3A7; 
  /* color: #677E84; */
  color:#9C030C;
}


a:focus{
  color:#9C030C;
  text-decoration: underline !important;
}

/* @supports (-webkit-text-stroke: 1px white) {
  nav a{
    -webkit-text-stroke: 0.1rem white;
    -webkit-text-fill-color: 100px black;
  }
} */


header {
    color:#F4F8F5;
}

header h1{
  padding:1rem;
}

header p{
  padding-left: 1rem;
}

.navContainer {
  min-width:240px !important;
  background-color: rgba(255, 255, 255, 0.75) !important;
  border-radius: 20px;
  min-height:250px;
  height:fit-content; 
  line-height:1;
}

    
/* media queries */
@media screen and (max-width: 768px) {
 a{
    margin: 0 !important;
    /* font-size: 2rem; */

  }

  .navigation{
    display: flex;
    flex-direction: column;
  }

}