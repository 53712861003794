.card {
    background-color: #C8B3A7;
    border-radius: 7px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    width: 350px;
    min-width: 200px;
    margin: 1rem;
    margin-top:1rem;
    align-items: center;
    text-align: center;
    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }

.card a{

  background-color: transparent;
  border-radius: 4px;
  /* color:#9C030C;  */  
  color: #677E84;
  cursor: pointer;
  font: inherit;
  padding: 0.5rem 1.5rem;
  
  
}

.card a:hover{
  /* color: #677E84; */
  color:#9C030C;
}

.card:hover{
  /* background-color: #E5684A ; */
  border: 1px dashed white;/*draw border*/
  border-image: url('../projects/assets/images/brick.jpeg') 30/ .5rem round;
  border-radius: 7px;
  
  /* color: white !important; */
  transform: scale(107%);
  /* color: #677E84; */
}
.card h4{
  text-decoration: underline;
}

.card span{
  font-size: 1em;
  margin:0.5rem;
}

.card .content{
  margin:0;
}


  